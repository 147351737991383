
  import { computed, defineComponent, ref } from 'vue';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { useAbility } from '@casl/vue';
  import Datatable from '@/components/kt-datatable/KTDatatable.vue';
  import { Actions } from '@/store/enums/StoreEnums';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import { Field } from 'vee-validate';
  import { useRoute, useRouter } from 'vue-router';

  export default defineComponent({
    name: 'properties-listing',
    emit: ['list-changed', 'show-edit-property-modal'],
    props: {
      createdAuction: { type: Object, default: null },
    },
    components: {
      Datatable,
      Field,
    },
    async setup(props, { emit }) {
      const { t, te } = useI18n();
      const { can } = useAbility();
      const store = useStore();
      const checkedProperties = ref([]);
      const isModalOpen = ref(false);
      const isModalOpenCopy = ref(false);
      const eventDatetime = ref();
      const eventId = ref();
      const route = useRoute();
      const router = useRouter();
      const submitButton = ref<HTMLButtonElement | null>(null);

      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const tableHeader = ref([
        {
          key: 'checkbox',
          sortable: false,
        },
        {
          name: translate('id'),
          key: 'index',
          sortable: false,
        },
        {
          name: translate('propertyTypeText'),
          key: 'propertyType',
          sortable: false,
        },
        {
          name: translate('area'),
          key: 'areaName',
          sortable: false,
        },
        {
          name: translate('status'),
          key: 'auctionStatus',
          sortable: false,
        },
        {
          name: translate('createdAt'),
          key: 'createdAt',
          sortable: true,
        },
        {
          name: translate('status'),
          key: 'isActive',
          sortable: true,
        },
        {
          name: translate('startingPrice'),
          key: 'startingPrice',
          sortable: true,
        },
        {
          name: translate('actions'),
          key: 'actions',
          sortable: false,
        },
      ]);
      const tableData = computed(() => store.getters.auctionPropertiesList);

      const toggleStatusProperty = async (property) => {
        const deletedMessage = property.isActive
          ? 'confirmInActive'
          : 'confirmActive';
        const result = await Swal.fire({
          title: translate('areYouSureQ'),
          icon: 'warning',
          text: translate(deletedMessage),
          showCancelButton: true,
          buttonsStyling: false,
          confirmButtonText: translate('confirm'),
          cancelButtonText: translate('cancel'),
          customClass: {
            confirmButton: 'btn fw-bold btn-warning',
            cancelButton: 'btn btn-light',
          },
        });

        if (result.value) {
          await store.dispatch(Actions.DELETE_AUCTION_PROPERTY, property.id);
          const [errorName] = Object.keys(store.getters.getErrors);
          const error = store.getters.getErrors[errorName];
          if (error) {
            Swal.fire({
              text: translate(error[0]),
              icon: 'error',
              buttonsStyling: false,
              confirmButtonText: translate('tryAgainExcl'),
              customClass: {
                confirmButton: 'btn fw-bold btn-light-danger',
              },
            });
          } else {
            const successMsg = property.isActive
              ? 'SUCCESSFULLY_INACTIVATED'
              : 'SUCCESSFULLY_ACTIVEATED';
            Swal.fire({
              text: translate(successMsg),
              icon: 'success',
              buttonsStyling: false,
              confirmButtonText: translate('ok'),
              customClass: {
                confirmButton: 'btn btn-light-primary',
              },
            });
          }
          emit('list-changed');
        }
      };

      const editOption = (id) => {
        emit('show-edit-property-modal', id);
      };

      store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-over');

      const openModal = () => {
        isModalOpen.value = true;
      };

      const closeModal = () => {
        isModalOpen.value = false;
      };

      const openModalCopy = () => {
        isModalOpenCopy.value = true;
      };

      const closeModalCopy = () => {
        isModalOpenCopy.value = false;
      };

      const saveClone = async () => {
        if (!eventDatetime.value) return;
        if (checkedProperties.value.length <= 0) return;
        if (!Number(route.params.id)) return;
        if (submitButton.value) {
          // eslint-disable-next-line
          submitButton.value!.disabled = true;
          submitButton.value.setAttribute('data-kt-indicator', 'on');
        }
        const obj = {
          eventDatetime: new Date(eventDatetime.value),
          propertiesIds: checkedProperties.value.map((id) => Number(id)),
          auctionsEventId: Number(route.params.id),
        };

        const data = await store.dispatch(Actions.CLONE_AUCTION_PROPERTY, obj);
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];
        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          submitButton.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        } else {
          const newActionUrl =
            'https://panel.alhisba.com/manage/auctions/auctions-editing/' +
            Number(data);
          Swal.fire({
            html:
              translate('SUCCESSFULLY_CREATED_AUCTION') +
              ' <a href="' +
              newActionUrl +
              '" target="_blank" style="color: blue; text-decoration: underline;">' +
              newActionUrl +
              '</a>',
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          }).then(async () => {
            //Deactivate indicator
            submitButton.value?.removeAttribute('data-kt-indicator');
            // eslint-disable-next-line
            submitButton.value!.disabled = false;
            window.location.reload();
            // console.log(data);
            // router.push({ name: 'auctions-editing', params: { data } });
            // await store.dispatch(Actions.RESET_AUCTIONS_PROPERTYS);

            // const routeData = router.resolve({
            //   name: 'auctions-editing',
            //   params: { data },
            // });

            // window.open(routeData.href, '_blank'); // Opens in a new tab
          });
        }
      };

      const saveCopy = async () => {
        if (!eventId.value) return;
        if (checkedProperties.value.length <= 0) return;
        if (!Number(route.params.id)) return;
        if (submitButton.value) {
          // eslint-disable-next-line
          submitButton.value!.disabled = true;
          submitButton.value.setAttribute('data-kt-indicator', 'on');
        }
        const obj = {
          eventId: Number(eventId.value),
          propertiesIds: checkedProperties.value.map((id) => Number(id)),
          auctionsEventId: Number(route.params.id),
        };

        const data = await store.dispatch(Actions.COPY_AUCTION_PROPERTY, obj);
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];
        if (error) {
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          submitButton.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        } else {
          const newActionUrl =
            'https://panel.alhisba.com/manage/auctions/auctions-editing/' +
            Number(data);
          Swal.fire({
            html:
              translate('SUCCESSFULLY_CREATED_AUCTION') +
              ' <a href="' +
              newActionUrl +
              '" target="_blank" style="color: blue; text-decoration: underline;">' +
              newActionUrl +
              '</a>',
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          }).then(async () => {
            //Deactivate indicator
            submitButton.value?.removeAttribute('data-kt-indicator');
            // eslint-disable-next-line
            submitButton.value!.disabled = false;
            window.location.reload();
            // console.log(data);
            // router.push({ name: 'auctions-editing', params: { data } });
            // await store.dispatch(Actions.RESET_AUCTIONS_PROPERTYS);

            // const routeData = router.resolve({
            //   name: 'auctions-editing',
            //   params: { data },
            // });

            // window.open(routeData.href, '_blank'); // Opens in a new tab
          });
        }
      };

      return {
        editOption,
        tableHeader,
        tableData,
        translate,
        can,
        toggleStatusProperty,
        checkedProperties,
        isModalOpen,
        openModal,
        closeModal,
        isModalOpenCopy,
        openModalCopy,
        closeModalCopy,
        eventDatetime,
        eventId,
        saveClone,
        saveCopy,
        submitButton,
      };
    },
  });
